export enum BadgeType {
  ONE_TIME = 'one-time',
  REPEATABLE = 'repeatable',
  LEVELS = 'multi-level'
}

export enum LogicType {
  FILTER = 'filter',
  AGGREGATE = 'aggregate'
}

export enum Mode {
  EDIT = 'Edit',
  NEW = 'New',
  VIEW = 'View'
}

export enum BadgeState {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled'
}

export enum SelectControlType {
  ENTITY = 'entity',
  FIELD = 'field',
  OPERATOR = 'operator'
}

export enum NotificationPrompt {
  IMMEDIATE = 'Immediate',
  DELAYED = 'Delayed'
}

export enum TriggerType {
  BADGES = 'Badges',
  POINTS = 'Points',
  BOTH = 'Badges & Points'
}

export interface SelectConfig {
  control: string;
  bindLabel: string;
  itemsPath?: string;
  loading?: boolean;
  onChange: (event: any, index: number) => void;
}

export interface IMedalLevelsFormFieldConfig {
  level?: number,
  name?: string,
  minPoints?: number,
  maxPoints?: number,
  levelIconUrl?: string
}

export interface IRewardDefinitionType {
  points?: number,
  name?: string,
  description?: string,
  badgeUrl?: string
}